export const EMAIL = /^\S+@\S+$/
export const CHAR = /[A-Za-z]/
export const NUMBER = /^\d+$/
export const ONLY_LATIN = /^[a-z0-9()[\]!@#$%^&*/:;'",.{}+_ ]+$/i

export const NUMBER_PATTERN_VALIDATE = {
  value: NUMBER,
  message: 'Введите только числовые значения'
}

export const EMAIL_PATTERN_VALIDATE = {
  value: EMAIL,
  message: 'Неверный формат email'
}
