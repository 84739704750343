import React, { Fragment, useEffect } from 'react'
import { prop } from 'ramda'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import sectionPropTypes from './sectionPropTypes'
import Section from './Section'

import * as API from 'constants/api'
import { useTranslate } from 'utils/translate'
import { useList } from 'hooks'
import { useLocale } from 'contexts/LocaleProvider'
import Title from 'components/Title'
import { Checkbox, CheckboxGroup, FieldLabel, FieldWrap } from 'components/FormComponents'
import { Divider } from 'components/UI'

const StyledSection = styled(Section)`
  & ${Divider}:last-child {
    margin-bottom: 0;
  }
`

const ToggleLabel = styled(FieldLabel)`
  margin-bottom: 25px;
`
const RegQuestions = props => {
  const { id, title, description } = props

  const { t } = useTranslate()
  const { locale } = useLocale()

  const { control } = useFormContext()

  const questions = useList(API.QUESTIONS_LIST, null, false)

  useEffect(() => {
    questions.getList({ language: locale })
  }, [locale])

  const formedDescription = questions.isLoading
    ? 'registration_questions_loading_label'
    : description

  return (
    <StyledSection id={id}>
      <Title description={t(formedDescription)}>
        {t(title)}
      </Title>

      {questions.results.map(q => {
        const questionID = prop('id', q)
        const question = prop('text', q)
        const answers = prop('answers', q) || []

        return (
          <Fragment key={questionID}>
            <FieldWrap>
              <ToggleLabel>{question}</ToggleLabel>
              <Controller
                name={`answers.question_${questionID}`}
                control={control}
                defaultValue={''}
                render={({ value, onChange }) => (
                  <CheckboxGroup value={value} onChange={onChange}>
                    {answers.map(a => {
                      const answerID = prop('id', a)
                      const answer = prop('name', a)

                      return (
                        <Checkbox
                          key={answerID}
                          value={answerID}
                          label={answer}
                        />
                      )
                    })}
                  </CheckboxGroup>
                )}
              />
            </FieldWrap>
            <Divider margin={'30px'} />
          </Fragment>
        )
      })}
    </StyledSection>
  )
}

RegQuestions.propTypes = sectionPropTypes

export default RegQuestions
