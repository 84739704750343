import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import sectionPropTypes from './sectionPropTypes'
import Section from './Section'

import * as API from 'constants/api'
import * as PATTERNS from 'constants/fieldPatterns'
import { useTranslate } from 'utils/translate'
import Title from 'components/Title'
import { FieldWrap, Input, SearchFieldControl } from 'components/FormComponents'

const RegCompany = props => {
  const { id, title, description } = props

  const { t } = useTranslate()
  const { register, control } = useFormContext()

  return (
    <Section id={id}>
      <Title description={t(description)}>
        {t(title)}
      </Title>

      <FieldWrap>
        <SearchFieldControl
          name="ownership"
          control={control}
          rules={{ required: true }}
          label={t('registration_company_ownership_input_label')}
          api={API.OWNERSHIPS_LIST}
        />
      </FieldWrap>

      <FieldWrap>
        <Controller
          name={'name'}
          control={control}
          rules={{
            required: true,
            pattern: {
              value: PATTERNS.ONLY_LATIN,
              message: t('validate_latin_chars')
            }
          }}
          render={controller => (
            <Input
              {...controller}
              ref={register()}
              label={t('registration_company_name_input_label')}
              placeholder={t('registration_company_name_input_placeholder')}
              onChange={event => {
                const val = event.target.value
                const upperVal = val ? val.toUpperCase() : val
                return controller.onChange(upperVal)
              }}
            />
          )}
        />
      </FieldWrap>

      <FieldWrap>
        <SearchFieldControl
          name="ecoActivity"
          control={control}
          rules={{ required: true }}
          label={t('registration_company_oked_input_label')}
          api={API.OKED_LIST}
          params={{ onlySubKlass: true }}
        />
      </FieldWrap>

      <FieldWrap>
        <SearchFieldControl
          name="businessType"
          control={control}
          rules={{ required: true }}
          label={t('registration_company_business_type_input_label')}
          api={API.BUSINESS_TYPES_LIST}
          params={{ isPublic: true, excludeYtt: true }}
        />
      </FieldWrap>
    </Section>
  )
}

RegCompany.propTypes = sectionPropTypes

export default RegCompany
